import { Renderer } from '@unseenco/taxi'
import { fetch } from 'whatwg-fetch'

export default class PageInnovations extends Renderer {

  initialLoad() {
    const onDOMContentLoaded = () => {
      document.removeEventListener('DOMContentLoaded', onDOMContentLoaded)
      this.onEnter()
    }

    document.addEventListener('DOMContentLoaded', onDOMContentLoaded)
  }

  onEnter() {
    this.DOM = {
      loadMorePublicationsButton: document.querySelector('#load-publications'),
      loadMorePatentsButton: document.querySelector('#load-patents'),
      publicationsContainer: document.querySelector('.publications__grid'),
      patentsContainer: document.querySelector('.patents__grid'),
      publicationProgress: document.querySelector('#progress__publications'),
      patentProgress: document.querySelector('#progress__patents'),
      publicationsProgressBar: document.querySelector('.progressbar__publications'),
      patentProgressBar: document.querySelector('.progressbar__patents'),
    }
    console.log(this.DOM)
    this.publicationsPage = 1
    this.patentsPage = 1
    this.init()
  }

  init() {
    this.initLoadMorePublication()
    this.initLoadMorePatent()
  }

  initLoadMorePublication() {
    if (!this.DOM.loadMorePublicationsButton) return
    this.DOM.loadMorePublicationsButton.addEventListener('click', this.loadMorePublication.bind(this))
  }
  loadMorePublication() {
    this.publicationsPage++
    this.loadMore(
      this.publicationsPage,
      this.DOM.loadMorePublicationsButton.dataset.ajaxUrl,
      this.DOM.publicationsContainer,
      this.DOM.loadMorePublicationsButton,
      this.DOM.publicationProgress,
      this.DOM.publicationsProgressBar
    )
  }

  initLoadMorePatent() {
    if (!this.DOM.loadMorePatentsButton) return
    this.DOM.loadMorePatentsButton.addEventListener('click', this.loadMorePatent.bind(this))
  }
  loadMorePatent() {
    this.patentsPage++
    this.loadMore(this.patentsPage,
      this.DOM.loadMorePatentsButton.dataset.ajaxUrl,
      this.DOM.patentsContainer,
      this.DOM.loadMorePatentsButton,
      this.DOM.patentProgress,
      this.DOM.patentProgressBar
    )
  }

  loadMore(page, url, container, button, progressSpan, progressBar) {
    fetch(`${url}&paged=${page}`)
      .then(response => response.json())
      .then(data => {
        const html = data.data.html
        const displayLoadMoreButton = data.data.pagination.displayLoadMore
        if (! displayLoadMoreButton) {
          button.style.display = 'none'
        }
        container.insertAdjacentHTML('beforeend', html)
        progressSpan.innerHTML = data.data.pagination.current
        console.log('% progress', data.data.pagination.current * 100 / data.data.pagination.total, '%')
        progressBar.style.width = `${data.data.pagination.current * 100 / data.data.pagination.total}%`
      })
  }

}
