import { isDEVMODE, viewStorage } from '../_globals'
import WknSwiper from '../modules/wkn-swiper'
import gsap from 'gsap'

export default class MobileSliders {

    constructor() {
      this.DOM = {
        mobileSliders: viewStorage.current.querySelectorAll('[mobile-slider]')
      }
  
      if (this.DOM.mobileSliders.length === 0) return
  
      viewStorage.hasMobileSliders = true
  
      this.init()
    }
  
    init() {
      if (isDEVMODE) console.log('Init Mobile Sliders')
  
      this.setMobileSliders()
    }

    setMobileSliders = () => {
        const {mobileSliders} = this.DOM
        const mm = new gsap.matchMedia()
        this.sliders = []

        mm.add("(max-width: 768px)", () => {

          mobileSliders.forEach(slider => {
            if (window.innerWidth < 768) {
              const cards = Array.from(slider.children)
              const wrapper = document.createElement('div')
              wrapper.classList.add('swiper-wrapper')
              slider.appendChild(wrapper)
  
              cards.forEach((card) => {
                const slide = document.createElement('div')
                slide.classList.add('swiper-slide')
                slide.appendChild(card)
                wrapper.appendChild(slide)
              })
  
              this.initMobileSlider(slider)
            }
          })

          return () => {
            this.sliders.forEach((slider) => {
              const wrapper = slider.DOM.el.querySelector('.swiper-wrapper')
              const slides = Array.from(wrapper.children)
              let cards = []

              slider.DOM.el.removeChild(wrapper)

              slides.forEach((slide) => {
                cards.push(slide.children[0])
              })

              cards.forEach((card) => {
                slider.DOM.el.appendChild(card)
              })

              slider.destroy()
            })

            this.sliders = []
          };
        });
    }

    initMobileSlider = (slider) => {
      this.sliders.push(
        new WknSwiper(
          slider,
          null,
          {
            slidesPerView: 1.1,
            spaceBetween: 12,
          }
        )
      )
    }

    destroy() {
        if (isDEVMODE) console.log('Destroy Mobile Sliders')
        viewStorage.hasMobileSliders = false
    }

}