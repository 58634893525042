import { isDEVMODE, globalStorage, domStorage, viewStorage } from '../_globals'
import gsap from 'gsap'
import {Observer} from 'gsap/Observer'
gsap.registerPlugin(Observer)

export default class Header {
  constructor() {
    this.DOM = { el: domStorage.header }
    this.DOM.brand = this.DOM.el.querySelector('.brand')
    this.DOM.toggle = this.DOM.el.querySelector('.Nav__toggle')
    this.DOM.menuContainer = this.DOM.el.querySelector('.Nav__container')

    this.setEvents()
  }

  setEvents() {
    const { overlay } = domStorage
    const { toggle } = this.DOM

    this.setObserver()

    // Toggle
    toggle.addEventListener('click', (e) => {
      e.preventDefault()
      globalStorage.menuOpen === true ? this.close() : this.open()
    })

    globalStorage.openMobileMenu = () => this.open()
    globalStorage.closeMobileMenu = () => this.close()

    // overlay
    overlay.addEventListener('click', (e) => {
      if (globalStorage.menuOpen) this.close()
    })

    // Resize
    this.resizeTimeout = setTimeout(() => {}, 0)
    this.onResize = this.onResize.bind(this)
    this.windowWidthResize = window.innerWidth
    window.addEventListener('resize', this.onResize)

  }

  open() {
    globalStorage.menuOpen = true
    domStorage.body.classList.add('--show-menu')
    viewStorage.viewScroll.scroll.stop()
  }

  close() {
    globalStorage.menuOpen = false
    domStorage.body.classList.remove('--show-menu')
    viewStorage.viewScroll.scroll.start()
  }

  onResize() {
    clearTimeout(this.resizeTimeout)
    this.resizeTimeout = setTimeout(() => {
      if (this.windowWidthResize !== window.innerWidth) {
        // if (isDEVMODE) console.log('updateHeaderResize')
        this.windowWidthResize = window.innerWidth
        if (globalStorage.menuOpen === true) this.close()
      }
    }, 250)
  }
  
  setObserver() {
    const { body } = domStorage
    const { el } = this.DOM

    this.onScrollObserver = Observer.create({
      target: window,
      type: 'scroll',
      tolerance: 0,
      onUp: () => !el.classList.contains('--visible') && el.classList.add('--visible'),
      onDown: () => el.classList.remove('--visible')
    })

    this.scrolledObserver = Observer.create({
      target: window,
      type: 'scroll',
      onChange: (self) => {
        if (window.scrollY > 20 && !body.classList.contains('--scrolled')) body.classList.add('--scrolled')
        if (window.scrollY <= 20 && body.classList.contains('--scrolled')) body.classList.remove('--scrolled')
      }
    })
  }
}
