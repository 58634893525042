/*
    Load Plugins / Functions
-------------------------------------------------- */
import { Core } from '@unseenco/taxi'
import { globalStorage } from './_globals'
import { onLeave, onEnter, onEnterCompleted } from './_events'

/* --- Renderers --- */
import Page from './pages/page'
import PageComponents from './pages/components'
import PageHome from './pages/home'
import PageWhoAreWe from './pages/who-are-we'

/* --- Transitions --- */
import PageTransitionDefault from './transitions/page-transition-default'
import PageContact from './pages/page-contact'
import PageInnovations from './pages/page-innovations'

/* --- Setup Core --- */
globalStorage.taxi = new Core({
  links: 'a:not([target]):not([href^=\\#]):not([data-taxi-ignore]):not(.ab-item)',
  renderers: {
    default: Page,
    pageComponents: PageComponents,
    pageHome: PageHome,
    pageContact: PageContact,
    pageWhoAreWe: PageWhoAreWe,
    pageInnovations: PageInnovations
  },
  transitions: {
    default: PageTransitionDefault
  }
})

/* --- Global Events --- */
globalStorage.taxi.on('NAVIGATE_IN', ({ to, trigger }) => onEnter(to, trigger))
globalStorage.taxi.on('NAVIGATE_OUT', ({ from, trigger }) => onLeave(from, trigger))
globalStorage.taxi.on('NAVIGATE_END', ({ to, from, trigger }) => onEnterCompleted(to, from, trigger))
