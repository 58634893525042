import { Renderer } from '@unseenco/taxi';
import { isDEVMODE, viewStorage, domStorage } from '../_globals'
import Anchors from '../modules/anchors'
import Form from '../modules/form'
import mapboxgl from 'mapbox-gl';

export default class PageContact extends Renderer {

  initialLoad() {
    const onDOMContentLoaded = () => {
      document.removeEventListener('DOMContentLoaded', onDOMContentLoaded)
      this.onEnter()
    }

    document.addEventListener('DOMContentLoaded', onDOMContentLoaded)
  }

  onEnter() {

    this.DOM = {
      contactForm: viewStorage.current.querySelector('.Form'),
      mapContainer: viewStorage.current.querySelector('.Map__container'),
      map : viewStorage.current.querySelector('#Map')
    }

    this.init()
  }

  init() {
    const { contactForm, mapContainer, map } = this.DOM

    if (contactForm) this.contactForm = new Form(contactForm)
    if (map) this.initMap()
    this.anchors = new Anchors(viewStorage.current.querySelector('.Page__header'))

    domStorage.app.style.background = "#ffffff"

    this.disableLenis = this.disableLenis.bind(this)
    this.enableLenis = this.enableLenis.bind(this)

    if (mapContainer) {
      mapContainer.addEventListener('mouseenter', this.disableLenis)
      mapContainer.addEventListener('mouseleave', this.enableLenis)
    }
  }

  initMap() {
    const { map } = this.DOM
    mapboxgl.accessToken = 'pk.eyJ1Ijoid29raW5lIiwiYSI6ImNsbml4Y2x3OTA3aWQycXAydDRkNXQ4ZHQifQ.Zf7RS8_cezu_QhojF5-Wiw';

    const { lat, lng } = map.dataset
    this.map = new mapboxgl.Map({
      container: map,
      style: 'mapbox://styles/wokine/cl7ei7nh8005014jvft859shf',
      center: [lng, lat],
      zoom: 16,
    });

    this.map.addControl(new mapboxgl.NavigationControl());
    this.map.scrollZoom.disable();

    // Marker
    const el = document.createElement('div');
    el.className = 'marker';
    new mapboxgl.Marker(el).setLngLat([lng, lat]).addTo(this.map);
  }

  disableLenis(e) {
    e && e.preventDefault()

    this.DOM.mapContainer.setAttribute('data-lenis-prevent', true)
  }

  enableLenis(e) {
    e && e.preventDefault()

    this.DOM.mapContainer.removeAttribute('data-lenis-prevent')
  }

  onLeaveCompleted() {
    this.contactForm && this.contactForm.destroy()
    this.map && this.map.remove()
    this.anchors && this.anchors.destroy()
    domStorage.app.style.background = null;

    if (this.DOM.mapContainer) {
      this.DOM.mapContainer.removeEventListener('mouseenter', this.disableLenis)
      this.DOM.mapContainer.removeEventListener('mouseleave', this.enableLenis)
    }
  }
}
