import { Renderer } from '@unseenco/taxi';
import { isDEVMODE, viewStorage } from '../_globals'
import WknSwiper from '../modules/wkn-swiper'

export default class PageWhoAreWe extends Renderer {

  initialLoad() {
    const onDOMContentLoaded = () => {
      document.removeEventListener('DOMContentLoaded', onDOMContentLoaded)
      this.onEnter()
    }

    document.addEventListener('DOMContentLoaded', onDOMContentLoaded)
  }

  onEnter() {
    this.DOM = {
      timelineSwiper: viewStorage.current.querySelector('.Timeline__swiper')
    }

    this.init()
  }

  init() {
    if (isDEVMODE) console.log(`Init Page : ${this.content.dataset.taxiView}`)
    if (isDEVMODE) console.log(viewStorage.current)

    if (this.DOM.timelineSwiper) this.swiperTimeline = new WknSwiper(this.DOM.timelineSwiper, null, { slidesPerView: 'auto' })
  }

  onLeaveCompleted() {
    this.swiperTimeline && this.swiperTimeline.destroy()
  }
}
