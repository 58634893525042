import { isDEVMODE, viewStorage } from '../_globals'

export default class Bubbles {

    constructor() {
      this.DOM = {
        bubbles: viewStorage.current.querySelectorAll('.Bubble')
      }
  
      if (this.DOM.bubbles.length === 0) return
  
      viewStorage.hasBubbles = true
  
      this.init()
    }
  
    init() {
      if (isDEVMODE) console.log('Init Bubbles')
  
      this.setBubbles()
    }

    setBubbles = () => {
        const {bubbles} = this.DOM

        bubbles.forEach(bubble => {
            const inner = bubble.querySelector('.Bubble__inner')
            const top = bubble.dataset.top
            const left = bubble.dataset.left
            const right = bubble.dataset.right
            const bottom = bubble.dataset.bottom

            const width = inner.dataset.width
            const mobileWidth = inner.dataset.mobileWidth

            inner.style.width = this.vw(width)
            bubble.style.top = top ? this.vw(top) : ''
            bubble.style.left = left ? this.vw(left) : ''
            bubble.style.right = right ? this.vw(right) : ''
            bubble.style.bottom = bottom ? this.vw(bottom) : ''
        })
    }

    vw(number) {
        return number / 14.4 + 'vw'
    }

    destroy() {
        if (isDEVMODE) console.log('Destroy Bubbles')
        viewStorage.hasBubbles = false
    }

}