import { Renderer } from '@unseenco/taxi'
import { isDEVMODE, viewStorage, domStorage } from '../_globals'
import WknSwiper from '../modules/wkn-swiper'
import gsap from 'gsap'
import {ScrollTrigger} from 'gsap/ScrollTrigger'
import { SplitText } from 'gsap/SplitText'
gsap.registerPlugin(ScrollTrigger)

export default class PageHome extends Renderer {

  initialLoad() {
    const onDOMContentLoaded = () => {
      document.removeEventListener('DOMContentLoaded', onDOMContentLoaded)
      this.onEnter()
    }

    document.addEventListener('DOMContentLoaded', onDOMContentLoaded)
  }

  onEnter() {
    this.DOM = {
      hero: viewStorage.current.querySelector('.Homepage__hero__background'),
      texts: viewStorage.current.querySelector('.Page__text').querySelector('.content').querySelectorAll('p'),
    }

    this.init()
  }

  init() {
    if (isDEVMODE) console.log(`Init Page : ${this.content.dataset.taxiView}`)
    if (isDEVMODE) console.log(viewStorage.current)
      
    !domStorage.header.classList.contains('--light') && domStorage.header.classList.add('--light')

    this.initScrollTriggerInit()
    this.initSwiper()
    this.initTextReveal()
  }

  initScrollTriggerInit() {
    if(!this.DOM.hero) {
      return
    }
    
    this.scrollTrigger = ScrollTrigger.create({
      trigger: this.DOM.hero,
      start: 'top bottom',
      end: 'bottom top',
      onLeave: () => {
        domStorage.header.classList.contains('--light') && domStorage.header.classList.remove('--light')
      },
      onEnterBack: () => {
        !domStorage.header.classList.contains('--light') && domStorage.header.classList.add('--light')
      }
    })
  }

  initSwiper() {
    this.swiperPrizes = new WknSwiper(
      viewStorage.current.querySelector('.Prizes__swiper'),
      viewStorage.current.querySelector('.Prizes__header__buttons'),
      {
        slidesPerView: 1.4,
        spaceBetween: 12,

        breakpoints: {
          1024: {
            slidesPerView: 2.3,
            spaceBetween: 16,
          },
          1440: {
            slidesPerView: 3.6,
            spaceBetween: 16,
          },
        }
      }
    )
  }

  initTextReveal() {
    if(this.DOM.texts.length === 0) {
      return
    }

    this.textAnims = []

    this.DOM.texts.forEach(item => {

      item.textsplit = new SplitText(item, {type: 'words', wordsClass: 'word__wrapper'})
      item.textsplitwords = new SplitText(item, {type: 'words', wordsClass: 'word'})

      item.textanimTL = gsap.timeline({ paused: true, immediateRender: true })
      item.textanimTL.fromTo(item.textsplitwords.words, { yPercent: 120 }, { yPercent: 0, stagger: 0.02, duration: 1, ease: 'power4.out' }, 0)

      item.textanimST = new ScrollTrigger.create({
        trigger: item,
        // markers: isDEVMODE,
        start: 'top bottom-=20%',
        once: true,
        onEnter: () => item.textanimTL.restart()
      })

      this.textAnims.push(item)

    })
  }

  onLeaveCompleted() {
    this.swiperPrizes.destroy()
    this.scrollTrigger && this.scrollTrigger.kill(true)
    domStorage.header.classList.contains('--light') && domStorage.header.classList.remove('--light')
    this.textAnims.forEach(item => {
      item.textsplit = null
      item.textsplitwords = null
      item.textanimTL.kill(true)
      item.textanimST.kill(true)
    })
  }
}
